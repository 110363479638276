import globals from "../globals";
import moment from "moment";

export default {
    computed: {
        globals () {
            return globals
        }
    },
    methods: {
        moment: moment
    }
}

import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./auth";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    countries: [],
    currencies: [],
    loading: false,
    confirmDialog: {
      show: false,
      title: '',
      text: '',
      confirmText: '',
      confirmColor: '',
      confirmFn: null,
      cancelFn: null
    }
  },
  mutations: {
    closeConfirm(state, type) {
      state.confirmDialog.show = false
      if(type === 'confirm')
      {
        if(state.confirmDialog.confirmFn) {
          new Promise(() => state.confirmDialog.confirmFn())
        }
      }
      else
      {
        if(state.confirmDialog.cancelFn) {
          new Promise(() => state.confirmDialog.cancelFn())
        }
      }
    },
    confirm(state, opt) {
      state.confirmDialog.title = opt.title || 'Please confirm'
      state.confirmDialog.text = opt.text || 'Are you sure?'
      state.confirmDialog.confirmText = opt.confirmText || 'Confirm'
      state.confirmDialog.confirmColor = opt.confirmColor || 'red'
      state.confirmDialog.confirmFn = opt.confirmFn || null
      state.confirmDialog.cancelFn = opt.cancelFn || null
      state.confirmDialog.show = true
    },
    setCountries(state, arr) {
      state.countries = arr
    },
    setCurrencies(state, arr) {
      state.currencies = arr
    },
    showLoading(state) {
      state.loading = true
    },
    hideLoading(state) {
      state.loading = false
    }
  },
  actions: {
  },
  modules: {
    auth
  }
})

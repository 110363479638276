<template>
  <div class="d-flex flex-column" style="height: 100%">
    <div class="bg"></div>
    <div>
      <div class="text-h3 text-center mt-10 mb-5">
        Thorbroadcast CRM
      </div>
      <div class="text-overline text-center">
        Fully automated management system.
      </div>
      <v-divider class="my-10"/>
      <div class="d-flex justify-center">
        <v-card outlined class="ma-5 px-15 py-5 d-flex align-center" v-for="(i, ik) in items.filter(x => x.show)" :key="ik" :to="i.to">
          <div class="d-flex flex-column align-center justify-center flex-grow-1">
            <v-btn :color="i.color ? i.color : 'purple'" fab depressed dark>
              <v-icon>{{ i.icon }}</v-icon>
            </v-btn>
            <span class="text-body-1 my-3">{{ i.name }}</span>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    computed: {
      items () {
        return [
          {
            icon: 'mdi-plus',
            name: 'Create invoice',
            to: {name:'invoices-new'},
            color: 'green',
            show: this.$store.state.auth.user && this.$store.state.auth.user.roles.includes('ROLE_ADMIN')
          },
          {
            icon: 'mdi-currency-usd',
            name: 'Invoices',
            to: {name:'invoices'},
            show: this.$store.state.auth.user && this.$store.state.auth.user.roles.includes('ROLE_ADMIN')
          },
          {
            icon: 'mdi-account-group',
            name: 'Customers',
            to: {name:'customers'},
            show: true
          },
          {
            icon: 'mdi-truck',
            name: 'Shipments to be sent',
            to: {name:'shipments'},
            show: this.$store.state.auth.user && !this.$store.state.auth.user.roles.includes('ROLE_WAREHOUSE')
          },
          {
            icon: 'mdi-archive',
            name: 'Warehouse',
            to: {name:'products'},
            show: true
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
.bg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 300px;
  background-size: contain;
  background-image: url('../assets/prawy-dolny.png');
  background-repeat: no-repeat;
}
</style>

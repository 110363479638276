import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/shelfs',
    name: 'shelfs',
    component: () => import('../views/Shelfs')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/Products')
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('../views/Customers')
  },
  {
    path: '/invoices/new',
    name: 'invoices-new',
    component: () => import('../components/InvoiceForm')
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('../views/Invoices')
  },
  {
    path: '/shipments',
    name: 'shipments',
    component: () => import('../views/Orders')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/ShopOrders')
  },
  {
    path: '/eans',
    name: 'eans',
    component: () => import('../views/EanList')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router

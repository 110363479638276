<template>
  <v-app v-if="!$store.state.auth.user">
    <v-main>
      <div class="d-flex align-center justify-center" style="height: 100%">
        <v-card width="100%" max-width="600px">
          <v-card-title>Please sign in</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-text-field
                label="Login"
                autofocus
                v-model="loginForm.login"
                name="login"
              />
              <v-text-field
                type="password"
                label="Password"
                v-model="loginForm.password"
                name="password"
              />
              <v-btn type="submit">Login</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </v-main>
  </v-app>
  <v-app v-else>
    <v-navigation-drawer v-model="drawer" app dark>
      <RouterLink to="/" tag="div" style="cursor: pointer">
        <div class="d-flex white--text align-center justify-center my-3">
          <img
            src="https://thorbroadcast.com/assets/img/thor-logo.png"
            style="height: 40px"
          />
          <span class="font-weight-black">CRM</span>
        </div>
        <v-divider dark />
        <v-list>
          <div v-for="(m, mk) in sidebar.filter(x => !x.canHide || (x.canHide && sidebarItemsShown))" :key="mk">
            <v-list-item v-if="!m.children" :to="m.to" :href="m.href">
              <v-list-item-icon v-if="m.icon">
                <v-icon>{{ m.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ m.name }}</v-list-item-title>
            </v-list-item>
            <v-list-group v-else>
              <template v-slot:activator>
                <v-list-item-icon v-if="m.icon">
                  <v-icon>{{ m.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ m.name }}</v-list-item-title>
              </template>

              <v-list-item
                v-for="(c, ck) in m.children"
                :key="ck"
                :to="c.to"
                :href="c.href"
                class="pl-10"
              >
                <v-list-item-icon v-if="c.icon">
                  <v-icon>{{ c.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ c.name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
      </RouterLink>
      <div slot="append">
        <div class="text-center pa-2">
          <v-btn x-small @click="toggleButtons()" depressed>
            <v-icon size="small" dense>{{ sidebarItemsShown ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
            {{ sidebarItemsShown ? 'hide' : 'show' }} warehouse buttons
          </v-btn>
        </div>
      </div>
    </v-navigation-drawer>
    <v-app-bar app color="black" dark dense flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex flex-grow-1 align-center">
        <v-btn v-if="!drawer" color="black" depressed href="/">
          <div class="d-flex align-center">
            <v-img
              src="https://thorbroadcast.com/assets/img/thor-logo.png"
              aspect-ratio="3"
              width="80px"
              contain
            />
            <div class="font-weight-black">CRM</div>
          </div>
        </v-btn>
        <v-spacer />
        Hello {{ $store.state.auth.user.username }}
        <v-btn x-small class="ml-4" @click="logout">logout</v-btn>
      </div>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <router-view />
      <div
        v-if="$store.state.loading"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255, 255, 255, 0.6); z-index: 10000000"
        class="d-flex align-center justify-center"
      >
        <v-progress-circular indeterminate size="100" color="red" />
      </div>
      <v-dialog
        v-model="$store.state.confirmDialog.show"
        max-width="400px"
        persistent
      >
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>{{
              $store.state.confirmDialog.title
            }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="$store.commit('closeConfirm')"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <div class="my-3">{{ $store.state.confirmDialog.text }}</div>
            <div class="d-flex">
              <v-spacer />
              <v-btn @click="$store.commit('closeConfirm', 'cancel')"
                >Cancel</v-btn
              >
              <v-btn
                @click="$store.commit('closeConfirm', 'confirm')"
                dark
                :color="$store.state.confirmDialog.confirmColor"
                class="ml-3"
              >
                {{ $store.state.confirmDialog.confirmText }}</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import globals from "./globals";
import functions from "./mixins/functions";

export default {
  name: "App",

  data: () => ({
    drawer: true,
    loginForm: {
      login: "",
      password: ""
    },
    sidebarItemsShown: window.localStorage.getItem('sidebarItemsShown') == '1'
  }),
  computed: {
    sidebar() {
      return [
        {
          name: "Home",
          icon: "mdi-home",
          href: "/"
        },
        ...(this.$store.state.auth.user &&
        this.$store.state.auth.user.roles.includes("ROLE_ADMIN")
          ? [
              {
                name: "Orders",
                icon: "mdi-cart",
                to: { name: "orders" }
              }
            ]
          : []),
        ...(this.$store.state.auth.user &&
        this.$store.state.auth.user.roles.includes("ROLE_ADMIN")
          ? [
              {
                name: "Invoices",
                icon: "mdi-currency-usd",
                to: { name: "invoices" }
              }
            ]
          : []),
        ...(this.$store.state.auth.user &&
        (this.$store.state.auth.user.roles.includes("ROLE_ADMIN") || this.$store.state.auth.user.roles.includes("ROLE_WAREHOUSE"))
          ? [
              {
                name: "Shipments",
                icon: "mdi-truck",
                to: { name: "shipments" }
              }
            ]
          : []),
        {
          name: "Customers",
          icon: "mdi-account-group",
          href: "/",
          to: { name: "customers" }
        },
        {
          name: "Warehouse",
          icon: "mdi-archive",
          canHide: true,
          children: [
            {
              name: "Warehouse",
              icon: "mdi-archive",
              to: { name: "products" }
            },
            {
              name: "Shelfs",
              icon: "mdi-table-large",
              to: { name: "shelfs" }
            }
          ]
        },
        {
          name: "Ean list",
          icon: "mdi-barcode",
          canHide: true,
          to: { name: "eans" }
        },
        ...(this.$store.state.auth.user &&
        this.$store.state.auth.user.roles.includes("ROLE_ADMIN")
          ? [
              {
                name: "Settings",
                icon: "mdi-cog",
                to: { name: "settings" }
              }
            ]
          : [])
      ];
    }
  },
  mixins: [functions],
  async created() {
    window.setInterval(() => {
      if (this.$store.state.currencies.length === 0) {
        axios.get(globals.apiUrl + "/currencies/list").then(res => {
          this.$store.commit("setCurrencies", res.data);
        });
      }
      if (this.$store.state.countries.length === 0) {
        axios.get(globals.apiUrl + "/countries/list").then(res => {
          this.$store.commit("setCountries", res.data);
        });
      }
    }, 2000);
    this.getUser();
  },
  methods: {
    toggleButtons () {
      this.sidebarItemsShown = !this.sidebarItemsShown
      window.localStorage.setItem('sidebarItemsShown', this.sidebarItemsShown ? '1' : '0')
    },
    logout() {
      axios.get(globals.apiUrl + "/auth/logout");
      this.$store.commit("setUser", null);
      localStorage.removeItem("token");
    },
    getUser() {
      if (localStorage.getItem("token")) {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem(
          "token"
        );
      }
      axios.get(globals.apiUrl + "/auth/user").then(res => {
        this.$store.commit("setUser", res.data);
      });
    },
    login() {
      delete axios.defaults.headers.common["Authorization"];
      axios
        .post(globals.apiUrl + "/auth/login", {
          username: this.loginForm.login,
          password: this.loginForm.password
        })
        .then(res => {
          localStorage.setItem("token", res.data.token);
          this.getUser();
          this.notyf("Success", "success");
        })
        .catch(reason => {
          this.notyf(reason.response.data.message, "error");
        });
    }
  }
};
</script>

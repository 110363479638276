import slugify from "slugify";
import {Notyf} from "notyf";
import 'notyf/notyf.min.css';

export default {
    methods: {
        upsTrackingLink(number) {
            return 'https://www.ups.com/track?tracknum=' + number
        },
        printBarcodes( data, type ) {
            if (!type) {
                type = 'code128'
            }
            const url = 'https://w.thorbroadcast.com/barcode/' + btoa(JSON.stringify(data) )+ '/' + type
            window.open(url)
        },
        generateEan () {
            let ean = '';
            let sum = 0;
            for(let i=0; i<12; i++) {
                const digit = Math.floor(Math.random() * 10)
                ean += digit
                sum += digit * (i%2 === 0 ? 1 : 3)
            }
            ean += Math.ceil(sum / 10) * 10 - sum
            return ean
        },
        slug (text) {
            return slugify(text).toUpperCase()
        },
        notyf(text, type)
        {
            if(!type) {
                type = 'success'
            }
            (new Notyf()).open({
                type,
                message: text
            })
        },
        countryFromAddress (address) {
            for (const i in this.$store.state.countries) {
                if (address.country === this.$store.state.countries[i].id) {
                    return this.$store.state.countries[i]
                }
            }
            return null;
        },
        provinceFromAddress (address) {
            const country = this.countryFromAddress(address)
            if (!country) {
                return null
            }
            for (const i in country.provinces) {
                if (country.provinces[i].id === address.province) {
                    return country.provinces[i]
                }
            }
            return null
        },
        waveInvoiceStatuses() {
            return [
                {value: 'DRAFT', text: 'Draft', color: 'grey lighten-2'},
                {value: 'SAVED', text: 'Saved', color: 'blue lighten-2'},
                {value: 'SENT', text: 'Sent', color: 'blue lighten-2'},
                {value: 'PAID', text: 'Paid', color: 'green lighten-2'},
                {value: 'OVERDUE', text: 'Overdue', color: 'red lighten-2'},
                {value: 'PARTIAL', text: 'Partial', color: 'orange lighten-2'},
                {value: 'UNPAID', text: 'Unpaid', color: 'red lighten-2'},
                {value: 'VIEWED', text: 'Viewed', color: 'orange lighten-2'}
            ]
        }
    },
    beforeCreate() {
        if(window._bc)
            return;
        window._bc = {
            barcode: '',
            lastPress: new Date(),
            handler: null,
            keyDown (e) {
                const key = e.key.toUpperCase()
                if (
                    key === 'ENTER'
                    || key === '-'
                    || /^[A-Z0-9]$/g.test(key)
                ) {
                    const now = new Date()
                    if (now.getTime() - window._bc.lastPress.getTime() > 300) {
                        window._bc.barcode = ''
                    }
                    window._bc.lastPress = now
                    if (key === 'ENTER' && window._bc.barcode.length > 0 ) {
                        if (window._bc.handler) {
                            window._bc.handler(window._bc.barcode)
                        }
                        window._bc.barcode = ''
                    } else if (key !== 'ENTER') {
                        window._bc.barcode += key
                    }
                }
            }
        }
        window.addEventListener('keydown', window._bc.keyDown)
    }
}
